<template>
  <div class="min-vh-100 vw-100 d-flex flex-column" style="background: linear-gradient(88deg, #050A30, #0C2D48);">
    <nav class="py-0 py-lg-1 navbar bg-blue justify-content-lg-between justify-content-center custom-header">
      <div class="container justify-content-lg-between justify-content-center" style="padding-top: 25px; padding-bottom: 25px;">
        <a class="navbar-brand text-white d-flex" href="#" style="align-items: center;">
          <img :src="images.logo" class="img-fluid my-2" style="max-width: 50px; margin-right: 10px;" />
          <p class="mb-0 ml-2">RU SOLUTIONS</p>
        </a>

        <div class="d-none d-lg-flex gap-3">
          <a
            class="nav-link text-white"
            href="#"
            :class="{ active: activeSection === '' }"
            style="text-decoration: none;"
            @click.prevent="scrollToSection('')"
          >INICIO</a>
          <a
            class="nav-link text-white"
            href="#services"
            :class="{ active: activeSection === 'services' }"
            style="text-decoration: none;"
            @click.prevent="scrollToSection('services')"
          >SERVICIOS</a>
          <a
            class="nav-link text-white"
            href="#projects"
            :class="{ active: activeSection === 'projects' }"
            style="text-decoration: none;"
            @click.prevent="scrollToSection('projects')"
          >PROYECTOS</a>
          <a
            class="nav-link text-white"
            href="#contact"
            :class="{ active: activeSection === 'contact' }"
            style="text-decoration: none;"
            @click.prevent="scrollToSection('contact')"
          >CONTACTO</a>
        </div>
      </div>
    </nav>

    <div style="background: linear-gradient(88deg, #050A30, #0C2D48)" class="py-5">
      <div class="container d-flex align-items-center justify-content-center main-height" style="">
        <div class="row w-100">
          <div class="col-lg-5 d-flex flex-column" style="justify-content: space-around;">
            <div class="d-flex flex-column justify-content-center">
              <div>
                <h1 class="text-white first-title">A TU ALCANCE LA</h1>
                <h1 class="text-white second-title">TRANSFORMACIÓN DIGITAL</h1>
                <p class="text-white subtitle">optimiza tu tiempo y empresa a la vez.</p>
              </div>
            </div>

            <div class="d-flex justify-content-center d-lg-block my-4 my-lg-0">
              <button
                class="btn btn-lg mb-0 align-self-end"
                style="background-color: rgb(240, 251, 82); border-radius: 24px; font-weight: bold; font-size: 20px; min-width: 60%; line-height: 30px; padding: 10px 0;"
                @click.prevent="scrollToSection('services')"
              >
                DESCÚBRELO
              </button>
            </div>
          </div>
          <div class="p-3 col-lg-7 d-flex justify-content-center mt-5 mt-lg-0 ">
            <img :src="images.computer" class="img-fluid w-100" />
          </div>
        </div>
      </div>
    </div>

    <div style="background: linear-gradient(295deg, #050A30, #0C2D48);" id="services" class="py-5 px-5">
      <div class="d-flex flex-column" style="min-height: 100vh; align-items: center; justify-content: center;">
        <h2 class="text-white text-center generic-title">Servicios</h2>

        <div class="row d-flex flex-lg-row flex-column">
          <!-- Web Development Column -->
          <div class="p-0 col-lg-4 d-flex flex-column mb-4 mb-lg-0 order-1 order-lg-0 px-lg-4 service-separator">
            <div class="d-flex align-items-center">
              <p class="text-white mb-0 text-nowrap service-title">Desarrollo Web</p>
              <img :src="images.webService" class="img-fluid w-100" style="max-width: 45px;" />
            </div>

            <ul style="margin-top: 10%;">
              <li class="item-text" style="color: #a6a6a6;">Landing Page</li>
              <li class="item-text" style="color: #a6a6a6;">Diseño Web Personalizado</li>
              <li class="item-text" style="color: #a6a6a6;">Desarrollo de Aplicaciones Web</li>
              <li class="item-text" style="color: #a6a6a6;">Optimización para Motores de Búsqueda (SEO)</li>
              <li class="item-text" style="color: #a6a6a6;">Integración de Comercio Electrónico</li>
              <li class="item-text" style="color: #a6a6a6;">Mantenimiento y Soporte Continuo</li>
            </ul>
          </div>

          <!-- Mobile Development Column -->
          <div class="p-0 col-lg-4 d-flex flex-column mb-4 mb-lg-0 order-2 order-lg-0 px-lg-4 service-separator">
            <div class="d-flex align-items-center">
              <p class="text-white mb-0 text-nowrap service-title">Desarrollo Móvil</p>
              <img :src="images.mobileService" class="img-fluid w-100" style="max-width: 45px;" />
            </div>

            <ul style="margin-top: 10%;">
              <li class="item-text" style="color: #a6a6a6;">Desarrollo de Aplicaciones Nativas / Híbridas</li>
              <li class="item-text" style="color: #a6a6a6;">Desarrollo de Aplicaciones Multiplataforma</li>
              <li class="item-text" style="color: #a6a6a6;">Optimización de Dispositivos Móviles</li>
              <li class="item-text" style="color: #a6a6a6;">Integración de Funcionalidades Avanzadas</li>
              <li class="item-text" style="color: #a6a6a6;">Mantenimiento y Actualizaciones Continuas</li>
            </ul>
          </div>

          <!-- Solution Architecture Column -->
          <div class="p-0 col-lg-4 d-flex flex-column mb-4 mb-lg-0 order-3 order-lg-0 px-lg-4">
            <div class="d-flex align-items-center">
              <p class="text-white mb-0 text-nowrap service-title">Arquitectura de Soluciones</p>
              <img :src="images.architectureService" class="img-fluid w-100" style="max-width: 45px;" />
            </div>

            <ul style="margin-top: 10%;">
              <li class="item-text" style="color: #a6a6a6;">Análisis y Consultoría</li>
              <li class="item-text" style="color: #a6a6a6;">Diseño de Arquitectura</li>
              <li class="item-text" style="color: #a6a6a6;">Desarrollo de Prototipos y MVP</li>
              <li class="item-text" style="color: #a6a6a6;">Integración de Sistemas</li>
              <li class="item-text" style="color: #a6a6a6;">Optimización y Escalabilidad</li>
              <li class="item-text" style="color: #a6a6a6;">Mantenimiento y Soporte Continuo</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div style="background: linear-gradient(88deg, #050A30, #0C2D48);" id="projects" class="py-5">
      <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 100vh;">
        <h2 class="text-white text-center generic-title">Proyectos</h2>

        <div class="container">
          <div class="grid-container" style="
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 350px));
            gap: 2.5rem;
            width: 100%;
            align-items: center;
            justify-content: center;
          ">
            <div class="grid-item" style="
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              background-color: rgb(255, 255, 252);
              padding: 2rem 1.25rem;
              border-radius: 2.5rem;
              transition: all 0.3s cubic-bezier(.4,0,.2,1);
              min-height: 450px;
            ">
              <div class="w-full h-12 d-flex flex-column justify-content-center mb-3">
                <p class="text-p700 mb-0.5 truncate px-10" style="font-size: 20px; font-weight: 600;">BUK</p>
              </div>

              <div class="mb-4" style="
                background-image: url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSO6Bq1X9prYiBh67NgNbzaBkhxkk9lyulJA&s);
                min-width: 250px;
                min-height: 250px;
                background-size: cover;
                border-radius: 32px;
              "></div>

              <div class="d-flex flex-wrap gap-1 justify-content-center text-p400 text-xs italic mb-2.5">
                <span class="project-description" style="font-size: 17px; font-weight: 400;">Aplicación Móvil Para Entregas de Inmuebles</span>
              </div>
            </div>

            <div class="grid-item" style="
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              background-color: rgb(255, 255, 252);
              padding: 2rem 1.25rem;
              border-radius: 2.5rem;
              transition: all 0.3s cubic-bezier(.4,0,.2,1);
              min-height: 450px;
            ">
              <div class="w-full h-12 d-flex flex-column justify-content-center mb-3">
                <p class="text-p700 mb-0.5 truncate px-10" style="font-size: 20px; font-weight: 600;">CRM Sperant</p>
              </div>

              <div class="mb-4" style="
                background-image: url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSO6Bq1X9prYiBh67NgNbzaBkhxkk9lyulJA&s);
                min-width: 250px;
                min-height: 250px;
                background-size: cover;
                border-radius: 32px;
              "></div>

              <div class="d-flex flex-wrap gap-1 justify-content-center text-p400 text-xs italic mb-2.5">
                <span class="project-description" style="font-size: 17px; font-weight: 400;">Aplicación Móvil Para Postventa de Inmuebles</span>
              </div>
            </div>

            <div class="grid-item" style="
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              background-color: rgb(255, 255, 252);
              padding: 2rem 1.25rem;
              border-radius: 2.5rem;
              transition: all 0.3s cubic-bezier(.4,0,.2,1);
              min-height: 450px;
            ">
              <div class="w-full h-12 d-flex flex-column justify-content-center mb-3">
                <p class="text-p700 mb-0.5 truncate px-10" style="font-size: 20px; font-weight: 600;">CRM Sperant</p>
              </div>

              <div class="mb-4" style="
                background-image: url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSO6Bq1X9prYiBh67NgNbzaBkhxkk9lyulJA&s);
                min-width: 250px;
                min-height: 250px;
                background-size: cover;
                border-radius: 32px;
              "></div>

              <div class="d-flex flex-wrap gap-1 justify-content-center text-p400 text-xs italic mb-2.5">
                <span class="project-description" style="font-size: 17px; font-weight: 400;">API de Clientes de Inmobiliarias</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="background: linear-gradient(295deg, #050A30, #0C2D48);" id="contact" class="py-5 px-5">
      <div class="d-flex flex-column align-items-center justify-content-center contact-min-height">
        <h2 class="text-white text-center generic-title">Contáctanos</h2>

        <div class="d-flex contact-width flex-column flex-lg-row">
          <div class="col-lg-6 d-none d-lg-block">
            <div class="text-white" style="font-weight: bold; font-size: 24px; padding-right: 30px; margin-bottom: 30px;">
              ¡Puedes contactarnos por nuestras redes, o enviando el formulario!
            </div>

            <div class="mb-3">
              <div class="text-white" style="font-size: 20px;">Correo electrónico</div>
              <a style="color: #EDF851; font-size: 20px; text-decoration: underline;" href="mailto:rensoliscastillo@gmail.com">rensoliscastillo@gmail.com</a>
            </div>

            <div class="mb-3">
              <div class="text-white" style="font-size: 20px;">Redes Sociales</div>
              <a style="color: #EDF851; font-size: 20px; text-decoration: underline;" href="https://www.instagram.com/ru.solutions/" target="_blank">@ru.solutions</a>
            </div>

            <div class="mb-3">
              <div class="text-white" style="font-size: 20px;">Teléfono</div>
              <a style="color: #EDF851; font-size: 20px; text-decoration: underline;" href="https://api.whatsapp.com/send?phone=943248740" target="_blank">+51 943248740</a>
            </div>
          </div>

          <form class="col-lg-6 d-flex" style="flex-direction: column;" @submit.prevent="submitForm">
            <input
              v-model="form.name"
              type="text"
              placeholder="Ingresa tu nombre"
              style="background-color: #fff; border-radius: 8px; padding: 10px 30px; margin-bottom: 20px;" />
            <input
              v-model="form.email"
              type="text"
              placeholder="Ingresa tu correo electrónico"
              style="background-color: #fff; border-radius: 8px; padding: 10px 30px; margin-bottom: 20px;" />
            <input
              v-model="form.phone"
              type="text"
              placeholder="Ingresa tu teléfono (Opcional)"
              style="background-color: #fff; border-radius: 8px; padding: 10px 30px; margin-bottom: 20px;" />
            <textarea
              v-model="form.message"
              type="text"
              placeholder="Ingresa algún mensaje (Opcional)"
              rows="3"
              style="background-color: #fff; border-radius: 8px; padding: 10px 30px; margin-bottom: 20px;" />

            <div class="d-flex" style="justify-content: flex-end;">
              <button class="contact-button" type="submit">ENVIAR MENSAJE</button>
            </div>
          </form>

          <div class="d-lg-none">
            <div class="text-white align-center my-5 text-center" style="font-weight: bold; font-size: 24px;">
              ¡O puedes contactarnos por nuestras redes!
            </div>

            <div class="mb-3">
              <div class="text-white text-center" style="font-size: 20px;">Correo electrónico</div>
              <a class="text-center" style="color: #EDF851; font-size: 20px; text-decoration: underline;" href="mailto:rensoliscastillo@gmail.com">rensoliscastillo@gmail.com</a>
            </div>

            <div class="mb-3">
              <div class="text-white text-center" style="font-size: 20px;">Redes Sociales</div>
              <a class="text-center" style="color: #EDF851; font-size: 20px; text-decoration: underline;" href="https://www.instagram.com/ru.solutions/" target="_blank">@ru.solutions</a>
            </div>

            <div class="mb-3">
              <div class="text-white text-center" style="font-size: 20px;">Teléfono</div>
              <div class="text-center" style="color: #EDF851; font-size: 20px; text-decoration: underline;" href="https://api.whatsapp.com/send?phone=943248740" target="_blank">+51 943248740</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import logo from './assets/images/logo.svg'
import computer from './assets/images/computadora.png'
import webService from './assets/images/web-service.png'
import mobileService from './assets/images/mobile-service.png'
import architectureService from './assets/images/architecture-service.png'

export default {
  name: 'App',

  data() {
    return {
      images: {
        logo,
        computer,
        webService,
        mobileService,
        architectureService,
      },
      activeSection: '',
      form: {
        name: '',
        email: '',
        phone: '',
        message: '',
      }
    }
  },

  methods: {
    async submitForm() {
      if (this.form.name === '' || this.form.email === '') {
        return
      }

      try {
        await axios.post('https://1egtdrsxgd.execute-api.us-east-1.amazonaws.com/default/tsolutions-saveContactForm', {
          contact_id: Date.now(),
          contact_name: this.form.name,
          contact_email: this.form.email,
          contact_phone: this.form.phone,
          contact_message: this.form.message
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        })

        this.$toast.open({
          type: 'success',
          message: '¡Gracias por comunicarte con nosotros!',
          position: 'top'
        })

        this.form = {
          name: '',
          email: '',
          phone: '',
          message: '',
        }
      } catch (error) {
        console.error('Error: ', error)
      }
    },

    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    },

    onScroll() {
      const sections = ['services', 'projects', 'contact']
      let currentSection = ''

      sections.forEach((section) => {
        const sectionElement = document.getElementById(section)
        const rect = sectionElement.getBoundingClientRect()
        if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
          currentSection = section
        }
      })

      if (window.scrollY === 0) {
        currentSection = ''
      }


      this.activeSection = currentSection
    },
  },

  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  },
}
</script>

<style>
* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

#app {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

.bg-blue {
  background-color: #0a1b3d;
}

.container {
  padding: 0 !important;
}

.container-fluid {
  max-width: 100vw;
}

.min-vh-100 {
  min-height: 100vh;
}

.vw-100 {
  width: 100vw;
}

.container.custom-width {
  max-width: 80%;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.nav-link.active {
  color: #EDF851 !important;
  text-decoration: underline !important;
  text-underline-offset: 10px;
  text-decoration-thickness: 1px;
}

.grid-container {
  padding: 0 2.5rem;
}

.first-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.second-title {
  font-weight: bold;
  font-size: 28px;
  text-align: center;
}

.subtitle {
  font-size: 18px;
  text-align: center;
}

.contact-width {
  width: 100%;
}

.contact-button {
  background-color: #EDF851;
  color: #000;
  font-weight: bold;
  padding: 10px 30px;
  border-radius: 50px;
  width: 100%;
  margin-top: 25px;
}

.project-description {
  text-align: center;
}

.item-text {
  font-size: 15px;
}

.service-title {
  font-size: 20px;
  margin-right: 10px;
}

.generic-title {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 15%;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }

  .item-text {
    font-size: 18px;
  }

  .service-title {
    font-size: 24px;
    margin-right: 15px;
  }

  .generic-title {
    font-size: 50px;
    margin-bottom: 15%;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }

  .grid-container {
    padding: 0 1rem;
  }

  .custom-header {
    position: sticky !important;
    top: 0;
    z-index: 1001;
  }

  .first-title {
    font-size: 35px;
    text-align: left;
  }

  .second-title {
    font-size: 40px;
    text-align: left;
  }

  .subtitle {
    font-size: 18px;
    text-align: left;
  }

  .main-height {
    min-height: calc(100vh - 116px);
  }

  .contact-min-height {
    min-height: 100vh;
  }

  .contact-width {
    width: auto;
  }

  .contact-button {
    width: auto;
    margin-top: 0;
  }

  .project-description {
    text-align: left;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }

  .first-title {
    font-size: 40px;
    text-align: left;
  }

  .second-title {
    font-size: 50px;
  }

  .subtitle {
    font-size: 22px;
  }

  .service-separator {
    border-right: 1px solid #545454;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1420px !important;
  }
}
</style>
